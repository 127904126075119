import React from "react"
import "./styles.scss"
import love from "../../images/transheart.svg";
import {Link} from "gatsby";
import { IconLogoFacebook, IconLogoInstagram, IconLogoPatronite, IconLogoPomagamPl } from "../Icons";
import useLinks from "../../hooks/useLinks";

export default function Footer({alts}) {
    const links = useLinks();
    return (
        <footer className={"site-footer"}>
            <div className="footer-contents">
                <span className={"icon-links"}>
                    <a href={links.facebook} aria-label="Facebook">
                        <IconLogoFacebook/>
                    </a>
                    <a href={links.instagram} aria-label="Instagram">
                        <IconLogoInstagram/>
                    </a>
                    <a href={links.pomagamPl} aria-label="Pomagam.pl">
                        <IconLogoPomagamPl/>
                    </a>
                    <a href={links.patronite} aria-label="Patronite">
                        <IconLogoPatronite/>
                    </a>
                </span>
                <p>© 2023 Fundacja Lambda Polska Wszelkie prawa zastrzeżone | <Link to="/polityka-prywatnosci">Polityka prywatności</Link></p>
                <p className={"credit"}>Made with <img src={love} alt={"love"}/> by cddlee</p>
            </div>
        </footer>
    )
}