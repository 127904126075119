import React from "react";
import "./styles.scss";
import { Link } from "gatsby";
import { IconLogoFacebook, IconLogoInstagram, IconLogoPatronite, IconLogoPomagamPl } from "../../../Icons";
import useLinks from "../../../../hooks/useLinks";

export default function Main() {
    const links = useLinks();
    return <div className="header-main">
        <Link to={"/"}>
            <img className="logo" src={'/images/logo-full-compact.svg'} alt={"Logo Lambdy Polska"}/>
        </Link>
        <div className={"header-icons"}>
            <div/>
            <a href={links.patronite} aria-label="Patronite"><IconLogoPatronite/></a>
            <a href={links.pomagamPl} aria-label="Pomagam.pl"><IconLogoPomagamPl/></a>
            <a href={links.instagram} aria-label="Instagram"><IconLogoInstagram/></a>
            <a href={links.facebook} aria-label="Facebook"><IconLogoFacebook/></a>
            <div/>
        </div>
    </div>
}