import React, {useEffect, useRef, useState} from "react";
import "./styles.scss";
import Footer from "../Footer";
import CookieBanner from "../CookieBanner";
import Header from "../Header";
import spinningBackground from "../../images/spinning-background.svg"

const LocaleContext = React.createContext(null)

export default function Layout({children, pageContext: {locale, alts, ignoreLayout} }) {
    const [areCookiesAllowed, setAreCookiesAllowed] = useState(true)
    useEffect(() => {
        const cookie = ('; ' + document.cookie).split('; allow_cookies=').pop().split(';').shift()
        if (cookie === "1")
            setAreCookiesAllowed(true)
        else if (cookie === "0") {
            setAreCookiesAllowed(false)
            if (process.env.NODE_ENV === "production" && window.gtag)
                window.gtag("consent", "update", {
                    "ad_storage": "denied",
                    "analytics_storage": "denied",
                })
        } else
            setAreCookiesAllowed(null)
    }, [])

    const background = useRef(null);
    const [isBackgroundSticky, setIsBackgroundSticky] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        background.current && setIsBackgroundSticky(background.current.getBoundingClientRect().height - window.innerHeight < window.scrollY)
      };
      handleScroll()
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
    return (
        <LocaleContext.Provider value={{locale}}>
            <div className={"site-root"}>
                <div className="spinning-background">
                    <div className={isBackgroundSticky ? "sticky" : ""} ref={background}><img src={spinningBackground} alt=""/></div>
                </div>
                {!ignoreLayout && <Header/>}
                <main className={"site-main"}>
                    {children}
                </main>
                {!ignoreLayout && <>
                    {areCookiesAllowed === null && <CookieBanner setAreCookiesAllowed={setAreCookiesAllowed}/>}
                    <Footer alts={alts}/>
                </>}
            </div>
        </LocaleContext.Provider>
    )
}

export {LocaleContext}
