module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CGVYNLHL0K"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"removeAccents":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fundacja Lambda Polska","short_name":"Lambda Polska","description":"Organizacja non-profit działająca na rzecz osób LGBTQ+ na terenie całego kraju","lang":"pl","start_url":"/","background_color":"#ffffff","theme_color":"#480882","display":"standalone","icons":[{"src":"icons/icon-maskable-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"icons/icon-maskable-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icons*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
