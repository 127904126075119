import React, {useRef, useState} from "react";
import StandardHeader from "./components/StandardHeader";
import MobileHeader from "./components/MobileHeader";
import "./styles.scss"
import {Link} from "gatsby";
import useClickOutside from "../../hooks/useClickOutside";
import { ExternalLinkIcon } from "../Page";

export default function Header() {
    const [expanded, setExpanded] = useState(false)

    // const markdownPages = useStaticQuery(graphql`
    // {
    //   allFile(filter: {sourceInstanceName: {eq: "pages"}, extension: {eq: "md"}}) {
    //     edges {
    //       node {
    //         childrenMdx {
    //           frontmatter {
    //             slug
    //             title
    //             order
    //             category
    //             draft
    //           }
    //         }
    //         name
    //       }
    //     }
    //   }
    // }`)

    const navLinksDictionary = {
      "wsparcie w tranzycji": "/wsparcie-w-tranzycji",
      "grupy wsparcia": "/grupy-wsparcia",
      "inna pomoc": "/pomoc-psychologiczna-prawna-kryzysowa",
      "dzientrans.pl": "https://dzientrans.pl/",
      "marsze równości": "/marsze-rownosci",
      "badania i artykuły": "/badania-i-artykuly",
      "projekty": "/projekty",
      "wesprzyj": "/pomoz-nam-pomagac",
      "o nas": "/o-nas",
      "statut": "/statut",
      "kontakt": "/#dane-kontaktowe"
    }

    const navLinks = Object.entries(navLinksDictionary).map(([text, route], index) => route.startsWith("http") ?
    <a key={index} href={route} target="_blank" rel="nofollow noopener noreferrer" onClick={() => {setExpanded(false)}}>{text}
        <ExternalLinkIcon/>
    </a> :
    <Link
        key={index} to={route} onClick={() => {setExpanded(false)}}>{text}
    </Link>)
    const refMobileHeader = useRef(null)
    const refMobileNav = useRef(null)
    useClickOutside([refMobileHeader, refMobileNav], () => setExpanded(false))
    return <div className="site-header-container">
    <nav className={`mobile-nav ${expanded ? "expanded" : ""}`} ref={refMobileNav}>
        {navLinks}
        <div className={"nav-end"}/>
    </nav>
    <header className={"site-header"}>  
        <StandardHeader navLinks={navLinks}/>
        <MobileHeader expanded={expanded} setExpanded={setExpanded} titleBarRef={refMobileHeader}/>
    </header>
    </div>
}